import React from 'react';

import Grid from '@mui/material/Grid';

import Page from '../components/Page';
import Section from '../components/Section';
import Typography from '../components/Typography';
import Card from '../components/Card';
import Quote from '../components/Quote';
import FeatureSlider from '../components/FeatureSlider';

import graphicStaff from '../images/graphic_staff.svg';
import innowellAssess from '../images/real_time.png';
import innowellInsight from '../images/insights.png';
import innowellContent from '../images/content.png';
import innowellHabit from '../images/habit.png';

import shield from '../images/icon-shield.svg';
import microscope from '../images/icon-microscope.svg';
import user from '../images/icon-user.svg';
import graph from '../images/icon-graph.svg';

const featureSlides = [
  {
    image: innowellAssess,
    title:'Real-time Assessments',
    content:'Clinical tools and instruments, carefully designed along with mental health experts at the Brain and Mind centre give live, meaningful insights into a user’s mental health care and pinpoint key focus areas.',
  },
  {
    image: innowellInsight,
    title:'Actionable Insights',
    content:'Individual privacy is protected at every level while giving your workplace deep, actionable insights into their health posture and recommending active tools to engage, support, and empower your people towards better mental health care.',
  },
  {
    image: innowellContent,
    title:'People-First Content',
    content:'A rich library of recommended articles, podcasts, activities, and more individualised for each user and designed to grow healthier self-management and understanding of personal mental health care.',
  },
  {
    image: innowellHabit,
    title:'Proactive Habit Tools',
    content:'Focus areas allow users to better self-manage mental health, and paired with habit-forming activities, help to grow healthier behaviour loops.',
  },
]

const YourStaff = () => {

  return (
    <Page title='Your Staff'>
      {/* Hero */}
      <Section>
        <Grid container direction='row-reverse'>
          <Grid item xs={12} md={6}
            sx={{
              textAlign: 'center',
            }}
          >
            <img alt='Graphic for Staff' src={graphicStaff}/>
          </Grid>
          <Grid item xs={12} md={6}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography type='hero' preset={1}>Healthy minds, healthy workplaces</Typography>
            <Typography type='paragraph' preset={2}>
              Research-based and people-centric, Innowell provides real-time metrics that give actionable insights into your company’s mental health posture and its people’s needs.
            </Typography>
          </Grid>
        </Grid>
      </Section>

      <Section>
        <Typography type='heading' preset={2}>The BIG problem (that we're working to fix)</Typography>
        <Typography type='paragraph' preset={2}>
          1 in every 2 Australians will suffer from ill mental health at some stage in their life, and in a 12-month period 21% of Australians will take time off work due to stress, anxiety, depression, or ill mental health.
        </Typography>
        <Typography type='paragraph' preset={2}>
          The estimated economic cost of this problem is $500 million a day, and those figures double when counting indirect costs – loss of productivity, absenteeism, turnover of staff, and its impact on co-workers. When research reveals 91% of workers believe mental health in the workplace is important but only 52% believing their workplace is mentally healthy, we know something needs to be fixed.
        </Typography>
        <Typography type='paragraph' preset={2}>
          Mental health support is no longer a secondary perk. It is a leadership strategy.
        </Typography>
        <Typography type='heading' preset={2}>Your Innowell Solution</Typography>

        <ul>
          <li>
            <Typography type='paragraph' preset={2}>Science-backed tools and pathways that focus on reflection, self-management, and reshaping behaviour loops.</Typography>
          </li>
          <li>
            <Typography type='paragraph' preset={2}>Growing a 'mental health first' workplace culture that doesn’t just improve quality-of-life for employees but works proactively to strengthen community wellbeing and happiness.</Typography>
          </li>

          <li>
            <Typography type='paragraph' preset={2}>Proactive, not reactive, tools that support all areas of employee wellbeing, through seven domains of mental health.</Typography>
          </li>

          <li>
            <Typography type='paragraph' preset={2}>
              Lead a mental health first culture and help your people unlock better self-care, with Innowell.
            </Typography>
          </li>
        </ul>
      </Section>

      {/* Quote */}
      <Section theme='mint'>
        <Quote content='A relationship with your employers should mean better mental health – not burnout and anxiety.'/>
      </Section>

      {/* Icon Grid */}
      <Section>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Card center>
              <img src={graph} alt='Insight Focused icon'/>
              <Typography type='heading' preset={3}>Insight Focused</Typography>
              <Typography type='paragraph' preset={2}>Real-time, configurable analysis and deep analytics that give you control over meaningful, actionable reporting.</Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card center>
              <img src={user} alt='People Centric icon'/>
              <Typography type='heading' preset={3}>People Centric</Typography>
              <Typography type='paragraph' preset={2}>Tools backed by real science and pathways tailored to individual needs. </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card center>
              <img src={shield} alt='Privacy First icon'/>
              <Typography type='heading' preset={3}>Secure & Private</Typography>
              <Typography type='paragraph' preset={2}>We never share data unless you want us to.</Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card center>
              <img src={microscope} alt='Science Backed icon'/>
              <Typography type='heading' preset={3}>Science Backed</Typography>
              <Typography type='paragraph' preset={2}>More than 10 years of research and over a dozen research trials. </Typography>
            </Card>
          </Grid>
        </Grid>
      </Section>

      {/* The Clinical Platform */}
      <Section title='The Innowell Platform'>
        <FeatureSlider slides={featureSlides}/>
      </Section>

    </Page>
  )
};

export default YourStaff;
